





















import TriggerSetup from "@/components/TriggerSetup/TriggerSetup.vue";
import {
  ActionsType,
  ActionsWrapperType,
  ConditionsType,
  ConditionsWrapperType,
  RawTriggerData,
  TriggerData
} from "@/components/TriggerSetup/logic/types/types";
import { TriggerFactory } from "@/components/TriggerSetup/logic/factories/trigger-factory";
import { errorNotification } from "@/includes/NotificationService";
import TriggerControls from '@/components/TriggerSetup/components/TriggerControls/TriggerControls.vue';
import NetworksApi from "@/includes/Api/Networks.api";
import { deleteNetworkTrigger, saveNetworkTrigger } from "@/includes/logic/Networks/logic";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";

import { Component, Mixins, Watch } from 'vue-property-decorator';
import { cloneDeep, isEqual } from "lodash";
import { Route } from "vue-router";
import { NavigationGuardNext } from "vue-router/types/router";

@Component({
  data() {
    return {
      TriggerControls,
    }
  },
  components: {
    TriggerSetup
  }
})
export default class NetworkTrigger extends Mixins(NetworksHelperMixin) {
  triggerModel: TriggerData | null = null

  @Watch('triggerModel', { deep: true })
  onTriggerModelChange() {
    this.defaultAction = false
  }

  triggerBackup: TriggerData | null = null

  triggerFactory: TriggerFactory = new TriggerFactory()

  blacklistedActions: Array<ActionsType | ActionsWrapperType> = [  ]
  blacklistedConditions: Array<ConditionsType | ConditionsWrapperType> = [  ]

  isTriggerValid = false

  defaultAction = false

  get triggerAction(): string | undefined {
    return this.$route.params.actionType?.toString()
  }

  get networkId(): number {
    return this.$store.state.networksState.currentNetwork!.id
  }

  get triggerId(): string | undefined {
    return this.$route.params.triggerId?.toString()
  }

  get controlsListeners() {
    const listeners: Record<string, Function> = {}

    listeners['save'] = this.handleSave
    listeners['save-no-move'] = this.handleSaveNotMove

    if (this.triggerAction === 'edit') {
      listeners['remove'] = this.handleRemove
    }

    return listeners
  }

  gotoNetworkTriggers() {
    this.$router.push({ name: "NetworkTriggers" })
  }

  handleSaveNotMove(rawTriggerData: RawTriggerData) {
    this.defaultAction = true

    this.saveTrigger(rawTriggerData)
      .then(res => {
        if (res) {
          this.triggerBackup = cloneDeep(this.triggerFactory.createNewTriggerFromExists(res))

          if (this.triggerId !== res.id || this.triggerAction !== 'edit') {
            this.gotoNetworkTrigger('edit', res.id, 'replace')
          }
        }
      })
  }

  handleSave(rawTriggerData: RawTriggerData) {
    this.defaultAction = true

    return this.saveTrigger(rawTriggerData)
      .then(res => {
        if (res) {
          this.triggerBackup = cloneDeep(this.triggerFactory.createNewTriggerFromExists(res))

          this.gotoNetworkTriggers()
        }
      })
  }

  saveTrigger(rawTriggerData: RawTriggerData) {
    this.$baseTemplate.loader.open()

    return saveNetworkTrigger(this.$store.state.networksState.currentNetwork, this.triggerId!, rawTriggerData)
      .then(res => res)
      .finally(() => {
        this.$baseTemplate.loader.close()
      })
  }

  handleRemove() {
    deleteNetworkTrigger(this.networkId, this.triggerId!)
      .then(res => {
        if (res) {
          this.gotoNetworkTriggers()
        }
      })
  }

  getTrigger(triggerId: string) {
    return NetworksApi.getNetworkTrigger('tg', this.networkId, triggerId)
      .then(({ data }) => this.triggerFactory.createNewTriggerFromExists(data))
      .catch(errorNotification)
  }

  async created(): Promise<void> {
    let triggerData: TriggerData | null = null

    if (this.triggerAction === 'new') {
      if (this.$store.state.networksState.triggers.triggerToCopy) {
        triggerData = this.triggerFactory.createNewTriggerFromExists(this.$store.state.networksState.triggers.triggerToCopy)

        this.$store.commit('SET_TRIGGER_TO_COPY', null)
      } else {
        triggerData = this.triggerFactory.createNewTrigger({ chatId: this.networkId })
      }
    } else if (this.triggerAction === 'edit') {
      if (this.triggerId) {
        triggerData = await this.getTrigger(this.triggerId) ?? null
      }
    }

    if (!triggerData) {
      this.gotoNetworkTriggers()
    }

    this.triggerModel = triggerData
    this.triggerBackup = cloneDeep(triggerData)
  }

  beforeWindowUnload(e:BeforeUnloadEvent) {
    if(!isEqual(this.triggerBackup, this.triggerModel)) {
      return e.returnValue = this.$t('leave_trigger_setting_warn_text').toString();
    }
  }

  beforeRouteLeave(to: Route, from:Route, next:NavigationGuardNext): void {
    if(!isEqual(this.triggerBackup, this.triggerModel) && !this.defaultAction) {
      const content = this.isTriggerValid
        ? this.$t('leave_trigger_setting_warn_text').toString()
        : this.$t('leave_trigger_setting_warn_invalid_text').toString()

      const okText = this.isTriggerValid
        ? this.$t('save_and_leave').toString()
        : this.$t('fix_setting').toString()

      this.$confirm({
        title: this.$t('warning').toString(),
        content,
        okText,
        cancelText: this.$t('leave').toString(),
        onOk: () => {
          next(false)

          if (this.isTriggerValid) {
            this.handleSave(this.triggerFactory.triggerDataToRawTriggerData(this.triggerModel!))
              .then(() => {
                next()
              })
          }
        },
        onCancel: () => {
          next()
        }
      })
    } else {
      next()
    }
  }

  mounted():void {
    this.$baseTemplate.saveButton.hide()
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  }

  destroyed():void {
    this.$baseTemplate.saveButton.show()
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  }

}
